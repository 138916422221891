.blogWrapper {
    height: auto;
    /* background-color: var(--primary); */
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 20%;
}

.blogTitle p {
    margin:8px;
    margin-top: 15%;
    color: var(--white);
    font-size: 2rem;
    font-weight: 450;
}

.scrollBlogContainer {
    height:50vh;
    display: inline;
    overflow-x: scroll;
    /* margin-bottom: 42px; */
}

.scrollBlogContainer::-webkit-scrollbar {
    height: 0px;
}

.scrollBlogContainer::-webkit-scrollbar-track {
    background: transparent;
}

.scrollBlogContainer::-webkit-scrollbar-thumb {
    background: rgb(105, 105, 105);
    border-radius: 10px !important;
}

.scrollBlogContainer::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.blogContainer {
    margin: 8px;
    background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
    backdrop-filter: blur(10px);
    /* box-shadow: 0px 0px 20px rgba(30, 30, 30, 0.8); */
    border-radius: 10px;
    padding: 8px;
    height: auto;
    width: 90% !important;
}

.blogImage {
    width: 100%;
    height: 55%;
    border-radius: 10px;
}

.blogContainer p {
    margin: 0;
    color: var(--white);
    font-size: 14px;
    padding-left: 4px;
}

.blogContainer h2 {
    margin: 0;
    padding-top: 8px;
    font-size: 20px;
    text-align: left;
    font-weight: 400;
    color: white;
    height: auto;
}

.footerNewsContainer {
    text-decoration: none;
}

.blogContainer a {
    border: 2px solid white;
    padding: 4px 16px 4px 16px;
    border-radius: 10px;
    text-decoration: none;
    color: var(--white);
    margin-top:15px;
}

@media (max-width:1250px) {
    .blogTitle p {
        margin-top: 64px;
    }

    .blogContainer h2 {
        font-size: 18px;
        font-weight: 300;
    }
}

@media (max-width:901.5px) {
    .blogTitle p {
        margin-top: 15%;
        font-size: 1.5rem;
        font-weight: 400;
    }
    .blogContainer h2 {
        font-size: 14px;
    }
}
@media (max-width:836px) {
    .blogTitle p {
        margin-top: 15%;
        font-size: 1.5rem;
        font-weight: 400;
    }
    .blogContainer h2 {
        font-size: 12px;
        font-weight: 300;
    }
}

@media (max-width:599.5px) {
    .blogTitle p {
        font-size: 1.5rem;
    }
    .scrollBlogContainer {
        height: 70vh;
    }
    .blogContainer {
        margin: 8px;
        background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
        backdrop-filter: blur(10px);
        /* box-shadow: 0px 0px 20px rgba(30, 30, 30, 0.8); */
        border-radius: 10px;
        padding: 8px;
        height: auto;
        width: 90% !important;
    }
    .blogContainer p {
        margin: 0;
        color: var(--white);
        font-size: 14px;
        padding-left: 4px;
    }
    
    .blogContainer h2 {
        margin: 0;
        padding-top: 8px;
        font-size: 20px;
        text-align: left;
        font-weight: 400;
        color: white;
        height: auto;
    }
}