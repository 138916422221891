.featuresWrapper {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5%;
    color: var(--primary);
}

.featuresTitleContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.featuresTitle p {
    margin: 0;
    font-size: 60px;
    font-weight: 600;
    text-align: center;
}

.featuresSubTitle p {
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    opacity: 0.7;
}

.featuresContainer {
    margin-top: 64px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}

.featuresCardsContainer {
    background-color: var(--button1);
    width: 250px;
    height: 300px;
    border-radius: 100%;
    padding: 2%;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    margin: 2%;
}

.featuresCardIconContainer {
    margin-top: 16px;
    margin-bottom: 24px;
    width: 70px;
    height: 70px;
    border-radius: 100px;
    background-color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
}

.featuresCardTitleContainer {
    margin-bottom: 24px;
}

.featuresCardTitleContainer p {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
}

.featuresCardTextContainer {
    text-align: center;
}

.featuresCardTextContainer p {
    text-align: center;
}

@media (max-width:415px) {
    .featuresTitle p {
        font-size: 50px;
    }
}

@media (max-width:350px) {
    .featuresTitle p {
        font-size: 40px;
    }

    .featuresSubTitle p {
        font-size: 18px;
    }
}