.cryptocurrencyWrapper {
    width: 100%;
    background-color: var(--primary);
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 60%; */
}

.cryptocurrencyLottieContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100% !important;
}
.cryptocurrencyInfoWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    margin:5%;
}

.cryptocurrencyInfoContainer {
    /* padding: 16px; */
    width: 100%;
    height: 60vh; 
    overflow-y: auto;
    cursor: pointer;
}

.cryptocurrencyInfoContainer::-webkit-scrollbar {
    width: 10px;
}

.cryptocurrencyInfoContainer::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.cryptocurrencyInfoContainer::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.cryptocurrencyInfoTitle {
    margin: 0;
}

.cryptocurrencyInfoText {
    font-size: 18px;
}
.css-1oqqzyl-MuiContainer-root{
    padding-left: 0 !important;
    padding-right: 0 !important;
}
