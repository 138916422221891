  @charset "utf-8"; 
/* CSS Document */

/******* Common Element CSS Start ******/
* {
  margin: 0px;
  padding: 0px;
  outline: none;
}

.CoinsImagesWrapper{

    width: 100%;
    background-color: var(--secondary);
}

.CoinsImagesContainer {
  background-color: var(--primary);
  width: 100%;
}

.main-container {
  min-height: 100vh;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  height: 560px;
  overflow: hidden;
}
.main {
  margin: 0px auto;
  width: 480px;
  height: 480px;
  position: relative;
}
.big-circle {
  height: 100%;
  width: 100%;
  position: relative;
  border: 3px solid #FDA736;
  border-radius: 50%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  animation: Rotate 20s linear infinite;
  -webkit-animation: Rotate 20s linear infinite;
}
.icon-block {
  width: 64px;
  height: 64px;
  position: absolute;
  border-radius: 50%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  background-image: linear-gradient(180deg, #FDA736 0%, #fbb65b 100%);
  -webkit-background-image: linear-gradient(180deg, #FDA736 0%, #fbb65b 100%);
  box-shadow: 0 2px 4px 0 #FDA736;
  -webkit-box-shadow: 0 2px 4px 0 #FDA736;
}
.icon-block img {
  margin: 0px auto;
  width: 86%;
  animation: Rotate-reverse 20s linear infinite;
  -webkit-animation: Rotate-reverse 20s linear infinite;
}
.icon-block:first-child {
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}
.icon-block:nth-child(2) {
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
  -webkit-transform: translate(50%, -50%);
}
.icon-block:nth-child(3) {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  -webkit-transform: translate(-50%, 50%);
}
.icon-block:nth-child(4) {
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}
/* circle content */
.circle {
  animation: circle-rotate 20s linear infinite;
  -webkit-animation: circle-rotate 20s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  width: 75%;
  height: 75%;
  border: 3px solid #FDA736;
  border-radius: 50%;
}
.circle .icon-block img {
  animation: img-rotate 20s linear infinite;
  -webkit-animation: img-rotate 20s linear infinite;
}
/* center logo */
.center-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}
.center-logo img {
  max-width: 200px;
}

/* keyframe animation */

@keyframes Rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes Rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes Rotate-reverse {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes Rotate-reverse {
  from {
    -webkit-transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0deg);
  }
}

@keyframes circle-rotate {
  from {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(405deg);
  }
}

@-webkit-keyframes circle-rotate {
  from {
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
  }
  to {
    -webkit-transform: translate(-50%, -50%) rotate(405deg);
  }
}

@keyframes img-rotate {
  from {
    transform: rotate(-45deg);
  }
  to {
    transform: rotate(-405deg);
  }
}

@-webkit-keyframes img-rotate {
  from {
    -webkit-transform: rotate(-45deg);
  }
  to {
    -webkit-transform: rotate(-405deg);
  }
}

@media (max-width:530px) {
    .main {
        margin: 0px auto;
        width: 360px;
        height: 360px;
        position: relative;
      }
}

@media (max-width:430px) {
    .main {
        margin: 0px auto;
        width: 300px;
        height: 300px;
        position: relative;
      }
      .icon-block {
        width: 50px;
        height: 50px;
      }
      .center-logo img {
        max-width: 100px;
      }
}
@media (max-width:360px) {
    .main {
        margin: 0px auto;
        width: 225px;
        height: 225px;
        position: relative;
      }
      .icon-block {
        width: 40px;
        height: 40px;
      }
      .center-logo img {
        max-width: 80px;
      }
      .circle {
        width: 70%;
        height: 70%;
      }
}