:root {
    --primary: #025C7C;
    --secondary: #025C7C;
    --secondary2: #4B4A54;
    --button1: #FDA736;
    --white: #f1f1f1;
    --black: #000;
    --card: #f1f1f1;
    --cardSubTitle: #023244;
}

@font-face {
    font-family:Arial, Helvetica, sans-serif;
    src: url(../src/assets/fonts/Lato-Regular.ttf);
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 100px !important;
    /* height: 50px !important; */
}

body {
    margin: 0px;
    font-family:Arial, Helvetica, sans-serif ;
}

body::-webkit-scrollbar {
    display: none;
}